<template>
  <OnboardingStep
    :title="$t('onboarding.constantContactSetupView.title')"
    :next-text="$t('onboarding.constantContactSetupView.nextText')"
    v-bind="$props"
  >
    <ConstantContactView v-bind="$props" />
  </OnboardingStep>
</template>

<script>
import { BaseStepView } from "./BaseStepView";
import ConstantContactView from "../Settings/AutomationView/ConstantContactView";

export default {
  name: "ConstantContactSetupView",
  components: { ConstantContactView },
  extends: BaseStepView,
};
</script>
